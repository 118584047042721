<template>
    <div >
        <template>
            <h6 style="color: red; text-align: right;">{{control_number}}</h6>
            <span v-for="(usageElement, usageIndex) in header_fields" v-bind:key="usageIndex">
                <CRow v-for="(rowElement, rowElementIndex) in usageElement" v-bind:key="rowElementIndex">
                   <template v-for="(singleElement, singleElementIndex) in rowElement" >
                        <CCol v-bind:key="singleElementIndex">
                            <template v-if="singleElement.field_id">
                                <template v-if="singleElement.field.parameter_name == 'logo' && singleElement.field.field_type.name == 'dropdown'">
                                    <div class="div-form-data-logo">
                                        <img :src="`/organization/${$store.getters.getOrganizationName == 'amarantos' ? 'amti' : $store.getters.getOrganizationName}/${header_footer_field_values[singleElement.id]}.png`" 
                                        v-if="header_footer_field_values[singleElement.id] != 'No Entity'"
                                        :alt="`${header_footer_field_values[singleElement.id]}`"
                                        class="company-from-data-logo">
                                    </div>
                                </template>
                                <template v-if="singleElement.field.parameter_name != 'logo'">

                                    <div :style="`white-space: pre-line; text-align:${singleElement.field.label_format}`" class="div-display-name" v-if="singleElement.field.field_type.name == 'label'">
                                        {{singleElement.field.label_value}}
                                    </div>
                                    <br v-if="singleElement.field.field_type.name == 'label'">

                                    <div class="div-display-name" v-else>{{singleElement.field_custom_name ? singleElement.field_custom_name : singleElement.field.display_name}}</div>

                                
                                    <label class="lbl-display-value" v-if="singleElement.field.field_type.name == 'date'">
                                        {{changeDateFormatV2(header_footer_field_values[singleElement.id])}}
                                    </label>

                                    <!-- Number only -->
                                    <label 
                                        class="lbl-display-value " 
                                        v-else-if="singleElement.field.field_type.name == 'number'"
                                    >
                                        {{header_footer_field_values[singleElement.id]}}
                                    </label>

                                    <!-- Number format with decimal only -->
                                    <label 
                                        class="lbl-display-value " 
                                        v-else-if="singleElement.field.field_type.name == 'number_format'"
                                    >
                                        {{numberFormatInForm(header_footer_field_values[singleElement.id])}}
                                    </label>

                                    <label class="lbl-display-value" style="white-space: pre-line;"  v-else>
                                        {{header_footer_field_values[singleElement.id]}}
                                    </label>

                                </template>
                            </template>
                            <template v-if="singleElement.aggregate_function && Object.keys(detail_field_values).length > 0">  <!-- Only shows when there is value in lines -->
                                <div class="div-display-name div-display-name-number">
                                    {{`${singleElement.aggregate_function[0].toUpperCase() + singleElement.aggregate_function.slice(1).toLowerCase()} of ${singleElement.aggregate_column_field ? singleElement.aggregate_column_field.display_name : null}`}}
                                </div>
                                <CInput 
                                    class="inp-aggregate-value"
                                    readonly
                                    type="text" 
                                    :value="singleElement.form_header_footer_field_value ? numberFormatInForm(singleElement.form_header_footer_field_value.value) : ''"
                                    autocomplete="off" 
                                />
                            </template>
                        </CCol>
                    </template>
                </CRow>
            </span>
        </template>
        <!-- <hr> -->
        <template>
            <CRow v-for="index in detailsRowCount" v-bind:key="index">
                <CCol lg="12" class="col-prnt">
                    <CRow>
                        <CCol class="col-chld" v-for="(detailElement, detailIndex) in detail_fields" v-bind:key="detailIndex">
                            <template v-if="detailElement.field_id">
                                <template v-if="detailElement.field.parameter_name == 'logo' && detailElement.field.field_type.name == 'dropdown'">
                                    <div class="div-form-data-logo">
                                        <img :src="`/organization/${$store.getters.getOrganizationName}/${detail_field_values['line_'+ index  +'#fieldid_'+detailElement.id]}.png`" 
                                        :alt="`${detail_field_values['line_'+ index  +'#fieldid_'+detailElement.id]}`"
                                        v-if="detail_field_values['line_'+ index  +'#fieldid_'+detailElement.id] != 'No Entity'"
                                        class="company-from-data-logo">
                                    </div>
                                </template>
                                <template v-if="detailElement.field.parameter_name != 'logo'">
                                    <!-- Label -->
                                    <div :style="`white-space: pre-line; text-align:${detailElement.field.label_format}`" class="div-display-name"  v-if="detailElement.field.field_type.name == 'label'">
                                        {{detailElement.field.label_value}}
                                    </div>
                                    <br v-if="detailElement.field.field_type.name == 'label'">
                                    
                                    <!-- Number -->
                                    <div class="div-display-name div-display-name-number"  v-if="detailElement.field.field_type.name == 'number' || detailElement.field.field_type.name == 'number_format'">
                                        {{detailElement.field_custom_name ? detailElement.field_custom_name : detailElement.field.display_name}}
                                    </div>
                                    <br v-if="detailElement.field.field_type.name == 'number' || detailElement.field.field_type.name == 'number_format'">

                                    <div class="div-display-name"  v-else>{{detailElement.field_custom_name ? detailElement.field_custom_name : detailElement.field.display_name}}</div>

                                    
                                    <!-- Date -->
                                    <label class="lbl-display-value" v-if="detailElement.field.field_type.name == 'date'">
                                        {{changeDateFormatV2(detail_field_values['line_'+ index  +'#fieldid_'+detailElement.id])}}
                                    </label>
                                    
                                    <!-- Number Only -->
                                    <label 
                                        class="lbl-display-value lbl-display-value-number" 
                                        v-else-if="detailElement.field.field_type.name == 'number'"
                                    >
                                        {{detail_field_values['line_'+ index  +'#fieldid_'+detailElement.id]}}
                                    </label>
                                    
                                    <!-- Number format with decimal Only -->
                                    <label 
                                        class="lbl-display-value lbl-display-value-number" 
                                        v-else-if="detailElement.field.field_type.name == 'number_format'"
                                    >
                                        {{numberFormatInForm(detail_field_values['line_'+ index  +'#fieldid_'+detailElement.id])}}
                                    </label>

                                    <label class="lbl-display-value" style="white-space: pre-line;" v-else>
                                        {{detail_field_values['line_'+ index  +'#fieldid_'+detailElement.id]}}
                                    </label>
                                </template>
                            </template>
                        </CCol>
                    </CRow>
                </CCol>
            </CRow>
        </template>
        <br>
        <template>
            <span v-for="(usageElement, usageIndex) in footer_fields" v-bind:key="usageIndex">
                <CRow v-for="(rowElement, rowElementIndex) in usageElement" v-bind:key="rowElementIndex">
                    <template v-for="(singleElement, singleElementIndex) in rowElement" >
                        <CCol v-bind:key="singleElementIndex">
                            <template v-if="singleElement.field_id">
                                <template v-if="singleElement.field.parameter_name == 'logo' && singleElement.field.field_type.name == 'dropdown'">
                                    <div class="div-form-data-logo">
                                        <img :src="`/organization/${$store.getters.getOrganizationName}/${header_footer_field_values[singleElement.id]}.png`" 
                                        :alt="`${header_footer_field_values[singleElement.id]}`"
                                        v-if="header_footer_field_values[singleElement.id] != 'No Entity'"
                                        class="company-from-data-logo">
                                    </div>
                                </template>
                                <template v-if="singleElement.field.parameter_name != 'logo'">

                                    <div :style="`white-space: pre-line; text-align:${singleElement.field.label_format}`" class="div-display-name" v-if="singleElement.field.field_type.name == 'label'">
                                        {{singleElement.field.label_value}}
                                    </div>

                                    <br v-if="singleElement.field.field_type.name == 'label'">

                                    <div class="div-display-name" v-else>
                                        {{singleElement.field_custom_name ? singleElement.field_custom_name : singleElement.field.display_name}}
                                    </div>
                                    
                                    <label class="lbl-display-value" v-if="singleElement.field.field_type.name == 'date'">
                                        {{changeDateFormatV2(header_footer_field_values[singleElement.id])}}
                                    </label>

                                    <!-- Number only -->
                                    <label 
                                        class="lbl-display-value " 
                                        v-else-if="singleElement.field.field_type.name == 'number'"
                                    >
                                        {{header_footer_field_values[singleElement.id]}}
                                    </label>

                                    <!-- Number format with decimal only -->
                                    <label 
                                        class="lbl-display-value " 
                                        v-else-if="singleElement.field.field_type.name == 'number_format'"
                                    >
                                        {{numberFormatInForm(header_footer_field_values[singleElement.id])}}
                                    </label>

                                    <label class="lbl-display-value" style="white-space: pre-line;" v-else>{{header_footer_field_values[singleElement.id]}}</label>
                                </template>
                            </template>
                           <template v-if="singleElement.aggregate_function && Object.keys(detail_field_values).length > 0">  <!-- Only shows when there is value in lines -->
                                <div class="div-display-name div-display-name-number">
                                    {{`${singleElement.aggregate_function[0].toUpperCase() + singleElement.aggregate_function.slice(1).toLowerCase()} of ${singleElement.aggregate_column_field ? singleElement.aggregate_column_field.display_name : null}`}}
                                </div>
                                <CInput 
                                    class="inp-aggregate-value"
                                    readonly
                                    type="text" 
                                    :value="singleElement.form_header_footer_field_value ? numberFormatInForm(singleElement.form_header_footer_field_value.value) : ''"
                                    autocomplete="off" 
                                />
                            </template>
                        </CCol>
                    </template>
                </CRow>
            </span>
        </template>
    </div>
</template>
<script>
export default {
    name: 'FormDataPreview',
    data() {
        return {
            masks: {
                input: 'DD-MMMM-YYYY',
            },
            control_number: null,
            detailsRowCount: 0,
            form: null,

            header_fields: null,
            detail_fields: null,
            footer_fields: null,

            header_footer_field_values: {},
            detail_field_values: {},
            organization_logo: null,
        }
    },
}
</script>